<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border student_list" outlined>
          <v-card-title class="title">
            <span v-if="!reuseComponent"> Data Entry </span>
            <span v-else>Bulk Admission</span>
            <v-spacer />
            <input
              type="file"
              style="display: none"
              ref="image"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              @change="onFilePicked"
            />

            <add-button
              v-if="$auth.isAdmin() || $auth.isSuper()"
              icon="search"
              color="warning"
              :permission="true"
              @action="reviewAllRecords"
            >
              Review All Records
            </add-button>
            &nbsp;&nbsp;
            <add-button
              v-if="!reuseComponent"
              :on-current-batch="false"
              :disabled="!gradeId || !!selectedData.length"
              :permission="reuseComponent ? true : 'batch-create'"
              @action="onPickFile"
            >
              Upload Excel File
            </add-button>
            <v-download-column-select
              v-if="reuseComponent"
              :optionType="'radio'"
              :showPDF="false"
              :columnHeader="'DOWNLOAD TYPES'"
              :downloadExcelText="'Begin Download/Upload'"
              :downloadColumns="downloadColumns"
              @onDownload="downloadResult"
            >
              DOWNLOAD/UPLOAD OPTION
            </v-download-column-select>
          </v-card-title>
          <!-- Grade select -->
          <v-card outlined>
            <v-card-title class="pb-0 mb-0">
              <v-col v-if="!reuseComponent" md="6">
                <v-flex>
                  <v-batch-field
                    label="Select batch"
                    :company-id="$route.params.id"
                    v-model="batchId"
                  ></v-batch-field>
                </v-flex>
              </v-col>
              <v-col md="6">
                <v-flex>
                  <v-grade-field
                    :disabled="!reuseComponent ? !!!batchId : false"
                    :batchId="batchId"
                    :company-id="$route.params.id"
                    v-model="gradeId"
                  ></v-grade-field>
                </v-flex>
              </v-col>
            </v-card-title>
          </v-card>
          <!-- Grade Select End -->
          <!-- ALERT MESSAGES -->
          <v-container v-if="duplicateRecord.length > 0 && selectedData.length">
            <alert-message
              type="error"
              title="Duplicate Entry Detected"
              :message="
                duplicateRecord.join(', ') +
                ' check these primary mobile numbers for duplicate entry.'
              "
            ></alert-message>
          </v-container>
          <v-container v-else-if="duplicateEmail.length && selectedData.length">
            <alert-message
              type="error"
              title="Duplicate Entry Detected"
              :message="
                duplicateEmail.join(', ') +
                ' check these emails for duplicate entry.'
              "
            ></alert-message>
          </v-container>
          <v-container
            v-else-if="sectionNotMatched.length && selectedData.length"
          >
            <alert-message
              type="error"
              title="Invalid Sections"
              :message="
                sectionNotMatched.length + ' students have invalid sections'
              "
            ></alert-message>
          </v-container>
          <v-container
            v-else-if="unprocessedRecord.length && selectedData.length"
          >
            <alert-message
              type="warning"
              title="Review Pending"
              :message="
                unprocessedRecord.length +
                ' students/guardian records needs your attention'
              "
            ></alert-message>
          </v-container>

          <v-container
            v-else-if="
              selectedData.length &&
              !sectionNotMatched.length &&
              !duplicateEmail.length &&
              !duplicateRecord.length &&
              !sectionNotMatched.length &&
              !unprocessedRecord.length
            "
          >
            <alert-message
              type="success"
              title="Looks good"
              :message="'Scroll to bottom and click the upload button to begin the upload process.'"
            ></alert-message>
          </v-container>
          <div
            v-if="selectedData.length"
            class="data-represent"
            style="margin-top: 8px"
          >
            <div>
              <span class="d-success"></span> &nbsp;
              <small
                ><strong>{{ data.length }} / {{ selectedData.length }}</strong>
                Total Guardians</small
              >
            </div>

            <div>
              <span class="d-error"></span> &nbsp;
              <small
                ><strong>{{ duplicateRecord.length }}</strong> Duplicate
                Records</small
              >
            </div>

            <div v-if="selectedData.length">
              <span class="d-info"></span> &nbsp;
              <small
                >Data Entry For &nbsp;<strong
                  >{{ selectedData[0].child_info.grade }}
                  /
                  {{ selectedData[0].child_info.section }}</strong
                >
              </small>
            </div>
          </div>
          <!-- END ALERT MESSAGES -->
          <!-- Upload Button -->
        
          <v-card-title class="pb-0 mb-0">
            <v-flex>
              <!-- {{}} -->
              <edit-button
                icon="null"
                :outlined="false"
                @agree="uploadRecord"
                v-if="$auth.isAdmin() || $auth.isSuper()"
                :disabled="
                  !selectedData.length ||
                  !sections.length ||
                  !batchId ||
                  !gradeId ||
                  !!duplicateRecord.length ||
                  !!duplicateEmail.length ||
                  !!sectionNotMatched.length ||
                  uploading ||
                  !!unprocessedRecord.length
                "
                :large="true"
                :message="`Are you sure you want to upload ${data.length} to live server.`"
                :block="true"
                color="primary"
                label="Upload Record"
                :permission="true"
              />
            </v-flex>
          </v-card-title>
          <!-- </v-card>
          </v-scroll-y-transition> -->
          <!-- End Upload Button -->

          <br />
          <!-- <Datatable start> -->
          <v-data-table
            v-model="selectedData"
            :headers="headers"
            @input="evaluateDuplicateRecords"
            :loading="fetchingRecords"
            :items="data"
            :server-items-length="data.length"
            :options.sync="pagination"
            :show-select="true"
            hide-default-footer
          >
            <template v-slot:item="{ index, item }">
              <tr :class="{ unprocess: item.is_processed === 2 }">
                <td @click="selectItem(item)">
                  <v-checkbox
                    :disabled="false"
                    primary
                    :input-value="selectedData.includes(item)"
                    hide-details
                  >
                  </v-checkbox>
                </td>
                <td class="text-xs-left">
                  {{ index + 1 }}
                </td>
                <td class="text-xs-left">
                  {{
                    item.guardian_info.f_name +
                    " " +
                    item.guardian_info.m_name +
                    " " +
                    item.guardian_info.l_name
                  }}

                  <div v-if="item.is_processed === 2">
                    <edit-button
                      edit-text="Review"
                      message="Are you sure you want to review the user"
                      @agree="reviewUser(item)"
                      color="error"
                      icon="account_circle"
                      label="Review why?"
                      permission="batch-create"
                    />

                    &nbsp;
                    <edit-button
                      @agree="form.edit(item)"
                      color="warning"
                      icon="account_circle"
                      label="Update"
                      permission="batch-create"
                    />

                    &nbsp;
                    <edit-button
                      edit-text="Insert"
                      @agree="uploadAnyWay(item)"
                      color="primary"
                      message="Are you sure you want insert this user anyway?"
                      icon="account_circle"
                      label="Insert Anyway"
                      permission="batch-create"
                    />
                  </div>
                </td>
                 <td>
                  <v-chip class="mb-2 mt-1"
                    >Name:
                    {{
                      item.child_info.f_name +
                      " " +
                      item.child_info.m_name +
                      " " +
                      item.child_info.l_name
                    }}
                  </v-chip>
                  &nbsp;<v-chip class="mb-2"
                    >DOB: {{ item.child_info.dob }}</v-chip
                  >
                  &nbsp;<v-chip class="mb-2"
                    >Gender: {{ item.child_info.gender }}</v-chip
                  >
                  &nbsp;<v-chip class="mb-2">
                    <v-icon v-if="!item.section_id" color="red">close</v-icon>
                    Grade/Section/Roll:
                    {{ item.child_info.grade }} /
                    {{ item.child_info.section }} /
                    {{ item.child_info.roll }}
                  </v-chip>
                  &nbsp;<v-chip class="mb-2">Email: {{item.child_info.email || 'N/A'}}</v-chip>
                  &nbsp;<v-chip class="mb-2">Primary No: {{item.child_info.primary_number || 'N/A'}}</v-chip>
                </td>
                <td>
                  <span
                    ><v-icon small>phone</v-icon>
                    {{ item.guardian_info.primary_mobile || "-" }}</span
                  >
                  <br />
                  <span
                    ><v-icon small>email</v-icon>
                    {{ item.guardian_info.email || "-" }}</span
                  >
                </td>
               

                <td>{{ item.guardian_info.gender }}</td>
                <td></td>
                
                <td>
                  {{ item.guardian_info.religion || "-" }} /
                  {{ item.guardian_info.ethnicity || "-" }}
                </td>
                <td>{{ item.relation_type }}</td>
                <td>
                  {{ item.guardian_info.city }} /
                  {{ item.guardian_info.street }} /
                  {{ item.guardian_info.ward_no }}
                </td>
               
              </tr>
            </template>
          </v-data-table>
          <v-card-title
            v-if="
              data.length && sections.length && gradeId
            "
          >
            <v-btn
              @click="deleteRecords"
              block
              large
              color="error"
            >
              Delete
            </v-btn>
          </v-card-title>
          <!-- <Datatable End> -->
        </v-card>
      </v-flex>
    </v-layout>
    <v-overlay :value="uploading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="reviewUserModal" persistent max-width="400px">
      <v-card>
        <v-card-text class="pb-1 pt-4">
          <v-card max-width="450" class="mx-auto elevation-0">
            <v-toolbar color="primary" dark>
              <v-app-bar-nav-icon></v-app-bar-nav-icon>

              <v-toolbar-title>User Review</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-toolbar>

            <v-list v-if="reviewUserModal" three-line>
              <v-subheader
                v-text="
                  'Reviewing: ' +
                  (comparedWith.guardian_info.f_name +
                    ' ' +
                    comparedWith.guardian_info.m_name +
                    ' ' +
                    comparedWith.guardian_info.l_name) +
                  '/' +
                  comparedWith.guardian_info.primary_mobile +
                  '/' +
                  comparedWith.guardian_info.email
                "
              ></v-subheader>

              <v-list-item>
                <v-list-item-avatar>
                  <v-img
                    :src="
                      reviewUserData.image
                        ? 'https://eacademy.sgp1.cdn.digitaloceanspaces.com/' +
                          reviewUserData.image.profile
                        : 'https://eacademy.sgp1.cdn.digitaloceanspaces.com/eacademy/school/elU1iAip1hgV2mZo6HV1hxvnLeoVmCIt8oQQQwN7.png'
                    "
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="reviewUserData.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle>
                    <p v-if="reviewUserData.address">
                      {{ reviewUserData.address.temporary.address }}
                    </p>
                    <p v-else></p>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="true" :inset="true"></v-divider>

              <v-list-item>
                <v-list-item-avatar>
                  <!--<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>-->
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="'Email / Phone'"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    >{{ reviewUserData.email || "N/A" }} /
                    {{ reviewUserData.primary_mobile }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="true" :inset="true"></v-divider>

              <v-list-item>
                <v-list-item-avatar>
                  <!--<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>-->
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="'Associate School'"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    >{{ reviewUserData.companies_name.join(", ") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="true" :inset="true"></v-divider>

              <v-list-item>
                <v-list-item-avatar>
                  <!--<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>-->
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    v-html="'Assigned Roles'"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    >{{ reviewUserData.roles_name.join(", ") }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="true" :inset="true"></v-divider>

              <v-list-item>
                <v-list-item-avatar>
                  <!--<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>-->
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="'Children'"></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-chip
                      v-for="(child, key) in reviewUserData.children"
                      :key="key"
                    >
                      {{ child.name }}
                    </v-chip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="true" :inset="true"></v-divider>

              <v-list-item>
                <v-list-item-avatar>
                  <!--<v-img src="https://cdn.vuetifyjs.com/images/lists/1.jpg"></v-img>-->
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-html="'Actions'"></v-list-item-title>
                  <v-list-item-subtitle>
                    <edit-button
                      edit-text="Assign"
                      message="Are you sure you want to assign this child to the guardian?"
                      @agree="assignChildren(comparedWith)"
                      color="success"
                      icon="account_circle"
                      :label="
                        'Assign Children (' +
                        comparedWith.child_info.f_name +
                        ')'
                      "
                      permission="batch-create"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="reviewUserModal = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="form.dialog" scrollable persistent max-width="500px">
      <v-card outlined class="primary--border">
        <v-card-title class="primary white--text">
          <span class="title">Update</span>
        </v-card-title>
        <v-card-text>
          <br />
          <div class="mt-2">
            <v-form
              ref="form"
              @submit.prevent="store"
              @keydown.native="form.errors.clear($event.target.name)"
              @keyup.enter="store"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-text-field
                      label="Mobile Number"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.mobile_number"
                      name="name"
                      outlined
                      dense
                      :error-messages="form.errors.get('mobile_number')"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      label="Email"
                      autocomplete="off"
                      required
                      class="pa-0"
                      v-model="form.email"
                      name="name"
                      outlined
                      dense
                      :error-messages="form.errors.get('email')"
                    />
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="warning"
            text
            @click="(form.dialog = false), form.reset()"
            >Close</v-btn
          >
          <v-btn
            color="success"
            outlined
            text
            :loading="form.loading"
            @click="updateRecord"
            >Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import Mixins from "@/library/Mixins";
import { mapState } from "vuex";
import Form from "@/library/Form";
export default {
  props: {
    reuseComponent: {
      default: false,
    },
    downloadColumns: {
      requied: false,
    },
  },
  mixins: [Mixins],
  data: () => ({
    gradeId: "",
    batchId: "",
    sheet: "",
    pagination: {
      rowsPerPage: 25,
    },
    fetchingRecords: false,
    headers: [
      {
        text: "#",
        align: "center",
        value: "id",
        width: 20,
        sortable: false,
      },
      {
        text: "Full Name",
        align: "left",
        value: "name",
        width: 370,
        sortable: false,
      },
      {
        text: "Student/Child",
        align: "left",
        value: "domain",
        sortable: false,
      },
      {
        text: "Mobile No",
        align: "left",
        value: "domain",
        width: 300,
        sortable: false,
      },
      
      {
        text: "Gender",
        align: "left",
        value: "short_code",
        width: 50,
        sortable: false,
      },
      {
        text: "Occupation",
        align: "left",
        value: "domain",
        width: 140,
        sortable: false,
      },
     
      {
        text: "Religion/Ethnicity",
        align: "left",
        value: "domain",
        width: 150,
        sortable: false,
      },
      {
        text: "Relation",
        align: "left",
        value: "domain",
        width: 80,
        sortable: false,
      },
      {
        text: "City / Street",
        align: "left",
        value: "domain",
        width: 200,
        sortable: false,
      },
      
    ],
    data: [],
    duplicateRecord: [],
    duplicateEmail: [],
    sectionNotMatched: [],
    unprocessedRecord: [],
    uploading: false,
    reviewUserModal: false,
    reviewUserData: {},
    comparedWith: {},
    selectedData: [],
    form: new Form({ mobile_number: "", email: "" }, "/api/data-entry"),
    enableDeleteButton: false,
  }),
  watch: {
    gradeId() {
      this.fetchSheetRecords();
    },
  },
  computed: {
    ...mapState(["grade", "batch"]),
    sections() {
      let sec = [];
      this.grade.grade.items.map((item) => {
        if (item.id === this.gradeId) {
          sec = item.sections;
        }
      });
      return sec;
    },

    companyIdConditional() {
      return this.reuseComponent
        ? this.$auth.company().id
        : parseInt(this.$route.params.id);
    },
  },

  methods: {
    SetbatchId() {
      if (this.reuseComponent) {
        this.batchId = this.batch.id;
      }
    },
    selectItem(item) {
      if (this.selectedData.indexOf(item) > -1)
        this.selectedData.splice(this.selectedData.indexOf(item), 1);
      else this.selectedData.push(item);
      this.evaluateDuplicateRecords();
    },
    onFilePicked(e) {
      this.sheet = e.target.files[0];
      const formData = new FormData();
      const companyId = this.companyIdConditional;
      if (this.reuseComponent) {
        this.batchId = this.batch.id;
      }
      if (companyId && this.gradeId && this.batchId) {
        let secNames = {};
        let filter_gradeName = this.grade.grade.items.filter((grade) => {
          if (grade.id == this.gradeId) {
            return grade;
          }
        });
        const sections = this.sections;
        sections.map((sec) => {
          secNames[sec.name.toLowerCase()] = sec.id;
        });
        let gradeName = filter_gradeName[0].name;

        formData.append("sheet", this.sheet);
        formData.append("sections", JSON.stringify(secNames));
        formData.append("gradeName", gradeName);
        this.$rest
          .post(
            `/api/prepare-sheet-records?grade_id=${this.gradeId}&company_id=${companyId}&batch_id=${this.batchId}`,
            formData
          )
          .then((res) => {
            this.fetchSheetRecords();
            this.$events.fire("notification", {
              message: res.data.message,
              status: "success",
            });
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
          })
          .finally(() => {
            this.$refs.image.value = null;
          });
      }
    },

    onPickFile() {
      this.$refs.image.click();
    },

    uploadRecord() {
      const companyId = this.companyIdConditional;
      if (companyId && this.gradeId && this.batchId) {
        this.uploading = true;
        this.$rest
          .post(
            `/api/upload-prepared-records?gradeId=${this.gradeId}&companyId=${companyId}&batchId=${this.batchId}`,
            { data: this.selectedData }
          )
          .then((res) => {
            this.$events.fire("notification", {
              message: "Upload was success",
              status: "success",
            });
            this.fetchSheetRecords();
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
          })
          .finally(() => {
            this.uploading = false;
          });
      }
    },

    uploadAnyWay(item) {
      const companyId = this.companyIdConditional;
      if (companyId && this.gradeId && this.batchId) {
        this.uploading = true;
        this.$rest
          .post(
            `/api/upload-prepared-records?gradeId=${this.gradeId}&companyId=${companyId}&batchId=${this.batchId}`,
            { data: [item] }
          )
          .then((res) => {
            this.$events.fire("notification", {
              message: "Upload was success",
              status: "success",
            });
            this.fetchSheetRecords();
          })
          .catch((err) => {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
          })
          .finally(() => {
            this.uploading = false;
          });
      }
    },

    fetchSheetRecords() {
      this.fetchingRecords = true;
      if (this.reuseComponent) {
        this.batchId = this.batch.id;
      }
      this.$rest
        .get(`/api/fetch-prepared-records?grade_id=${this.gradeId}`, {})
        .then((res) => {
          this.sectionNotMatched = [];
          this.unprocessedRecord = [];
          this.selectedData = [];
          this.enableDeleteButton = true;
          this.data = res.data.map((item) => {
            item.mobile_number = item.guardian_info.primary_mobile;
            item.email = item.guardian_info.email;
            if (item.is_processed === 2) {
              this.unprocessedRecord.push(item);
              this.enableDeleteButton = false;
            }
            this.selectedData.push(item);
            return item;
          });
          this.evaluateDuplicateRecords();
        })
        .finally(() => {
          this.fetchingRecords = false;
        });
    },

    evaluateDuplicateRecords() {
      const phoneCollection = [];
      const emailCollection = [];
      const duplicatePhone = [];
      const duplicateEmail = [];
      this.sectionNotMatched = [];
      this.unprocessedRecord = [];
      this.selectedData.map((item) => {
        if (item.is_processed === 2) {
          this.unprocessedRecord.push(item);
        }
        if (item.guardian_info) {
          if (
            item.guardian_info.primary_mobile &&
            phoneCollection.indexOf(item.guardian_info.primary_mobile) > -1
          ) {
            duplicatePhone.push(item.guardian_info.primary_mobile);
          }
          if (
            item.guardian_info.email &&
            emailCollection.indexOf(item.guardian_info.email) > -1
          ) {
            duplicateEmail.push(item.guardian_info.email);
          }

          phoneCollection.push(item.guardian_info.primary_mobile);
          emailCollection.push(item.guardian_info.email);
        }
        if (!item.section_id) {
          this.sectionNotMatched.push(item.child_info.f_name);
        }
      });
      this.duplicateRecord = duplicatePhone;
      this.duplicateEmail = duplicateEmail;
    },

    reviewUser(comparedWith) {
      let phone = comparedWith.guardian_info.primary_mobile;
      let email = comparedWith.guardian_info.primary_mobile;
      this.comparedWith = comparedWith;
      this.$rest
        .get(`/api/review-user?email=${email}&phone=${phone}`)
        .then(({ data }) => {
          this.reviewUserData = data;
          this.reviewUserData.companies_name = data.companies.map((item) => {
            return item.name;
          });
          this.reviewUserData.roles_name = data.roles.map((item) => {
            return item.name;
          });
          this.reviewUserModal = true;
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.message,
            status: "error",
          });
        });
    },

    assignChildren(comparedWith) {
      const companyId = this.companyIdConditional;

      this.uploading = true;
      this.$rest
        .post("/api/assign-children", {
          from: comparedWith,
          to: this.reviewUserData.id,
          companyId: companyId,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: "User assigned",
            status: "success",
          });
          this.fetchSheetRecords();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
        .finally(() => {
          this.uploading = false;
          this.reviewUserModal = false;
        });
    },

    deleteRecords() {
      const companyId = this.companyIdConditional;
      this.uploading = true;
      this.$rest
        .post("/api/delete-data-records", {
          grade_id: this.gradeId,
          company_id: companyId,
          data: this.selectedData,
        })
        .then((res) => {
          this.$events.fire("notification", {
            message: "Deleted",
            status: "success",
          });
          this.fetchSheetRecords();
        })
        .catch((err) => {
          this.$events.fire("notification", {
            message: err.response.data.message,
            status: "error",
          });
        })
        .finally(() => {
          this.uploading = false;
          this.reviewUserModal = false;
        });
    },

    updateRecord() {
      this.form.fireFetch = false;
      this.form.store().then(() => {
        this.fetchSheetRecords();
      });
    },

    reviewAllRecords() {
      const companyId = this.companyIdConditional;
      this.$rest
        .get("/api/review-all?company_id=" + companyId)
        .then((res) => {});
    },
    downloadResult({ type, columns, columnString }) {
      switch (columnString) {
        case "download_excel_raw":
          window.open(
            "https://eacademy.sgp1.digitaloceanspaces.com/eacademy/sample_data_entry_format.xlsx"
          );
          break;
        case "upload_excel":
          if (this.gradeId != "") {
            this.onPickFile();
          } else {
            this.$events.fire("notification", {
              message: "Select Grade First",
              status: "error",
            });
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.error_light {
  background-color: rgba(225, 0, 0, 0.2);
}

tr.unprocess {
  td {
    border-bottom: 4px solid indianred !important;
  }
}
</style>
